import hoverintent from 'hoverintent';
import kute from 'kute.js';
import 'kute.js/kute-svg';

document.querySelectorAll('.video-player__video-link').forEach(($video) => {
  const $path = $video.querySelector('.js-morph-path');
  const d1 = $path.getAttribute('d');
  const d2 = $path.getAttribute('data-d-hover');

  const options = {
    duration: 300,
  };

  hoverintent($video, () => {
    kute.fromTo($path, { path: d1 }, { path: d2 }, options).start();
  }, () => {
    kute.fromTo($path, { path: d2 }, { path: d1 }, options).start();
  });
});
