// Polyfills & shims
import 'mdn-polyfills/NodeList.prototype.forEach';
import 'mdn-polyfills/Element.prototype.matches';
import 'mdn-polyfills/Element.prototype.closest';
import 'mdn-polyfills/String.prototype.startsWith';
import 'intersection-observer';
import 'focus-visible';
import './shims/details';

// Globals
import './globals/external-video';
import './globals/sal';
import './globals/moving';

// Components
import '../components/video-player/video-player';
import '../components/vote-overlay/vote-overlay';
import '../components/video-list/video-list';
