/* eslint-disable no-param-reassign */
import hyperscript from 'hyperscript';
import isCallable from 'is-callable';

export const h = (type, props = {}, ...children) => {
  if (isCallable(type)) {
    return type(props);
  }

  props.attrs = props.attrs || {};

  Object.keys(props).forEach((key) => {
    if (key.startsWith('on') && key.length > 2) {
      props[key.toLowerCase()] = props[key];
      delete props[key];
    }

    if (key.startsWith('aria-')) {
      props.attrs[key] = props[key];
      delete props[key];
    }
  });

  return hyperscript(type, props, ...children);
};

export default h;
