import { on } from 'delegated-events';
import getVideoId from 'get-video-id';

on('click', '.js-external-video', (event) => {
  const $video = event.target.matches('.js-external-video') ? event.target : event.target.closest('.js-external-video');
  const { href } = $video;
  const { externalVideoClass = 'external-video' } = $video.dataset;

  if (href) {
    // Parse video url
    const video = getVideoId(href);
    if (!video.service || video.service !== 'youtube') {
      return;
    }

    let embed = `https://www.youtube-nocookie.com/embed/${video.id}?autoplay=1&rel=0`;

    if ($video.dataset.playlist) {
      embed = href;
    }

    // Build iFrame
    const $iframe = <iframe
      src={embed}
      className={externalVideoClass}
      frameBorder="0"
      allowFullScreen={true}
      title="YouTube-Video"></iframe>;

    // Render iFrame
    event.preventDefault();
    $video.parentNode.replaceChild($iframe, $video);
  }
});
