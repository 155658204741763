import getTarget from '../../javascripts/utils/get-target';

/* eslint-disable */
export function switchTab($tab) {
  // Remove focus from old tab
  const $oldTab = $tab.closest('[role=tablist]').querySelector('[aria-selected]');
  $oldTab.removeAttribute('aria-selected');
  $oldTab.setAttribute('tabindex', '-1');

  // Focus the new tab
  $tab.focus();
  $tab.removeAttribute('tabindex');
  $tab.setAttribute('aria-selected', 'true');

  // Hide old content
  const $oldContent = document.querySelector($oldTab.getAttribute('href'));
  $oldContent.hidden = true;
  $oldContent.tabIndex = '-1';

  // Stop any running YouTube player
  const $youtubeIFrame = $oldContent.querySelector('iframe');
  if ($youtubeIFrame) {
    $youtubeIFrame.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
  }

  // Show new content
  const $newContent = document.querySelector($tab.getAttribute('href'));
  $newContent.hidden = false;
  $newContent.tabIndex = '0';
}

document.addEventListener('click', (event) => {
  const $tab = getTarget(event.target, '.video-list__playlist-item');

  if ($tab) {
    event.preventDefault();
    if (!$tab.matches('[aria-selected]')) {
      switchTab($tab);
    }
  }
});
/* eslint-enable */

document.addEventListener('keydown', (event) => {
  const $tab = getTarget(event.target, '.video-list__playlist-item');

  if ($tab && event.which >= 37 && event.which <= 40) {
    event.preventDefault();

    const $wrap = $tab.parentNode;

    if (event.which === 38 && $wrap.previousElementSibling) {
      switchTab($wrap.previousElementSibling.querySelector('.video-list__playlist-item'));
    } else if (event.which === 40 && $wrap.nextElementSibling) {
      switchTab($wrap.nextElementSibling.querySelector('.video-list__playlist-item'));
    } else if (event.which === 37) {
      const $content = document.querySelector($tab.getAttribute('href'));
      $content.focus();
    }
  }
});
