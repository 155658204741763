// Create layer
const $layer = document.createElement('div');
$layer.classList.add('vote-overlay__layer');
document.body.appendChild($layer);

document.querySelectorAll('.vote-overlay__button').forEach(($button) => {
  const $content = $button.nextSibling;
  const $steps = $content.querySelector('.vote-overlay__steps');
  const $form = $content.querySelector('.vote-overlay__form-container');
  const $closeButton = $content.querySelector('.vote-overlay__close');
  const $endButton = $content.querySelector('.vote-overlay__end');

  // Create background
  const $background = document.createElement('div');
  $background.classList.add('vote-overlay__background');

  // Open overlay
  const openOverlay = () => {
    document.body.classList.add('no-scroll');
    $layer.appendChild($background);
    $layer.appendChild($content);
  };

  // Close overlay
  const closeOverlay = () => {
    document.body.classList.remove('no-scroll');
    $layer.removeChild($background);
    $layer.removeChild($content);

    if (($steps && $form) && ($steps.style.display === 'none' && $form.style.display === 'block')) {
      $steps.style.display = 'block';
      $form.style.display = 'none';
    }
  };

  // Show form
  const showForm = () => {
    if ($steps && $form) {
      $steps.style.display = 'none';
      $form.style.display = 'block';
    }
  };

  // Event listener
  $button.addEventListener('click', openOverlay);
  $background.addEventListener('click', closeOverlay);
  $closeButton.addEventListener('click', closeOverlay);

  if ($endButton) {
    $endButton.addEventListener('click', showForm);
  }
});
