import Horizon from '@mintuz/horizon';

function calculateVerticalPercentage(bounds) {
  const vh = window.innerHeight || 0;
  const percentage = (bounds.bottom) / (vh + bounds.height * 2);
  return 1 - Math.max(0, Math.min(1, percentage));
}

document.querySelectorAll('.js-moving').forEach(($el) => {
  const $$paths = $el.querySelectorAll('.js-moving-path');

  const handleScroll = () => {
    const bounds = $el.getBoundingClientRect();
    const percentage = calculateVerticalPercentage(bounds);

    window.requestAnimationFrame(() => {
      $$paths.forEach(($path) => {
        // eslint-disable-next-line no-param-reassign
        $path.style = `transform: translateY(${percentage * Math.min(bounds.height / 4, 100)}px);`;
      });
    });
  };

  Horizon({
    onEntry() {
      window.addEventListener('scroll', handleScroll, { passive: true });
      window.addEventListener('resize', handleScroll);
      handleScroll();
    },
    onExit() {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    },
    toObserve: $el,
  });
});
